import React from "react";
import { GlobalStyles } from "twin.macro";
import tw from "twin.macro";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import InnerTitle from "../components/InnerTitle";
import SponsorSlider from "../components/SponsorSlider";

const NotFoundPage = () => (
  <>
    <GlobalStyles />
    <Layout inner>
      <PageWrapper>
        <ContentWrapper>
          <InnerTitle>404: Page not found.</InnerTitle>
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  </>
);

export default NotFoundPage;
