import React from "react";
import tw from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import { flatMenuToHierarchical } from "../../lib/functions";

import Sidenav from "./Sidenav";

const Section = tw.aside`relative flex flex-col gap-40 items-stretch pr-12`;

const Sidenavs = ({ GZ, menus, showCategories, ...rest }) => {
  const createMenus = (rawMenus) =>
    rawMenus.map(({ menuItems, name }, i) => {
      return (
        <Sidenav
          GZ={GZ}
          key={i}
          title={name}
          menu={flatMenuToHierarchical(menuItems.nodes)}
        />
      );
    });
  const { allWpMenu, allWpCategory, wp } = useStaticQuery(graphql`
    {
      allWpMenu {
        nodes {
          name
          id
          count
          menuItems {
            nodes {
              label
              path
              id
              parentId
            }
          }
        }
      }
      wp {
        esquireAcf {
          firmInformation {
            defaultSidebarMenus {
              menu
            }
          }
        }
      }
      allWpCategory(filter: { name: { nin: ["Uncategorized", "Español"] } }) {
        edges {
          node {
            label: name
            path: uri
          }
        }
      }
    }
  `);
  const { defaultSidebarMenus } = wp?.esquireAcf.firmInformation;

  const menuTitles = (!!menus ? menus : defaultSidebarMenus)?.map(
    ({ menu }) => menu
  );

  let rawMenus = [];
  menuTitles?.forEach((menuTitle) => {
    const menu = allWpMenu.nodes.find((menu) => menu.name === menuTitle);
    if (menu) {
      rawMenus.push(menu);
    }
  });

  return (
    <Section {...rest}>
      {!!showCategories ? (
        <Sidenav
          GZ={GZ}
          title="Categories"
          categories
          menu={allWpCategory.edges}
        />
      ) : (
        createMenus(rawMenus)
      )}
    </Section>
  );
};

export default Sidenavs;
