import React, { useEffect, useRef } from "react";
import tw, { styled } from "twin.macro";
import anime from "animejs";
import { useInView } from "react-intersection-observer";

import SidenavItem from "./SidenavItem";
import Title from "./Title";

const Nav = styled.nav((GZ) => [
  tw`flex flex-col items-center w-full gap-10 px-6 py-12 relative shadow-xl translate-x-[150] opacity-0 before:(content[''] bg-secondary h-2 top-0 w-full left-0 absolute)`,

  GZ && tw`translate-x-0 opacity-100`,
]);
const Menu = styled.ul`
  ${tw` relative flex flex-col items-start w-full ml-6 pl-6 before:(content[''] w-0.5 h-full bg-secondary left-0 top-0 absolute)`}
`;

const Sidenav = ({ GZ, menu, title, categories, ...rest }) => {
  const { ref: observer, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const animateRef = useRef();
  useEffect(() => {
    // if (inView && !GZ)
    {
      anime({
        targets: animateRef.current,
        translateX: [150, 0],
        opacity: ["0%", "100%"],
        duration: 1250,
        easing: "easeInOutCubic",
      });
    }
  }, []);

  const listItems = (items) =>
    items.map((item, i) => {
      item = !!categories ? item.node : item;
      return <SidenavItem {...item} listFunc={listItems} key={i} />;
    });

  return (
    <Nav
      ref={animateRef}
      GZ={GZ}
      className="single-side-nav"
      css={["opacity:0"]}
    >
      <Title tw="after:(bg-secondaryLight w-[90%]) uppercase font-semibold mt-6">
        {title}
      </Title>
      <Menu>{listItems(menu)}</Menu>{" "}
    </Nav>
  );
};

export default Sidenav;
