import React from "react";
import tw, { styled } from "twin.macro";

const styles = () => [
  tw`text-4xl sm:text-[2.75rem] font-din leading-none mb-8`,
];

const H2 = styled.h2(styles);
const H1 = styled.h1(styles);

const InnerTitle = ({ h2, ...rest }) => {
  const Title = h2 ? H2 : H1;
  return <Title {...rest}></Title>;
};

export default InnerTitle;
