import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import AnimateHeight from "react-animate-height";
import { FaChevronDown, FaMinus } from "react-icons/fa";

const Item = styled.li(({ expanded }) => [
  tw`relative w-full py-2 font-bold duration-500 text-lg ease-in-out hover:text-primary`,
  expanded && tw`text-primary`,
]);
const ExpandButton = styled.button(({ expanded }) => [
  tw`absolute top-1 right-0 flex items-center justify-center w-6 h-6 p-0 text-white rounded-full bg-secondary transition-all duration-500 ease-in-out hover:(bg-secondaryLight)`,
  expanded === true &&
    tw`rotate-180 bg-secondary hover:(rotate-180 bg-secondaryLight)`,
]);
const Children = styled.ul``;
const MenuLink = styled(Link)(tw``);
const SidenavItem = ({
  listFunc,
  label,
  path,
  children,
  parentId,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <Item {...rest} expanded={expanded}>
      <div tw="flex flex-row items-center">
        <MenuLink to={path}>{label}</MenuLink>
        {!!children && children.length > 0 && (
          <ExpandButton onClick={toggleExpanded} expanded={expanded}>
            {expanded ? <FaMinus /> : <FaChevronDown />}
          </ExpandButton>
        )}
      </div>
      {!!children && children.length > 0 && (
        <>
          <AnimateHeight
            height={expanded ? "auto" : 0}
            duration={500}
            easing="ease-in-out"
          >
            <Children>{listFunc(children)}</Children>
          </AnimateHeight>
        </>
      )}
    </Item>
  );
};

export default SidenavItem;
