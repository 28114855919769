import React from "react";
import tw from "twin.macro";

const Section = tw.section`flex relative mt-32 gap-12 flex-col xl:(flex-row gap-0 mt-48 px-0) px-4 md:px-12 lg:px-20`;

const PageWrapper = ({ ...rest }) => {
  return <Section {...rest}></Section>;
};

export default PageWrapper;
