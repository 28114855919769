import React from "react";
import tw from "twin.macro";

const Article = tw.article`2xl:(pl-32) sm:pr-4 w-full max-w-full lg:(pl-12 pr-24) flex flex-col items-start relative`;

const ContentWrapper = ({ children, ...rest }) => {
  return <Article {...rest}>{children}</Article>;
};

export default ContentWrapper;
