import React from "react";
import tw from "twin.macro";

const Aside = tw.aside`w-full max-w-[27rem] 2xl:max-w-[33rem] gap-40 hidden relative xl:flex flex-col`;

const SideWrapper = ({ ...rest }) => {
  return <Aside {...rest}></Aside>;
};

export default SideWrapper;
